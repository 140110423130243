<!-- 解决方案 -->
<template>
  <div class="wrapper pageBox">
    <!-- banner -->
    <solutionBanner id="solutionBanner" ref="solutionBanner" :from="'tcgl'" />
    <!-- 二级导航 -->
    <navBar ref="navBar" :from="'tcgl'" />
    <!-- 行业分析 -->
    <div id="hyfx_div" class="fenxi-box">
      <h1 class="h1-title">行业分析</h1>
      <div class="fenxi conBox flex-between flex-align">
        <div class="imgs">
          <img class="img" src="@/assets/solution/cyyq/fenxi_tcgl.png">
        </div>
        <div>
          <div class="text">城市级停车管理常作为智慧城市建设的重要一极和切入口，其关键在于各方零散资源的整合管理。国内各种大型或超大型商业收费停车场（机场、体育场、展览中心）、中小型商业收费停车场（酒店、写字楼、商场、剧院配套）、小区停车场等都有相应的停车管理应用，且不同的停车场对系统软、硬件的要求都有所差异。传统的停车场管理系统只解决了出入口控制的问题，对于停车场内部的停车引导、找车、快速进出等功能则鞭长莫及，而且在收费这个环节上也存在缴费方式单一、人工管理效率低下、存在收费漏洞等问题，更别说进行停车场整体的系统整合及资源优化配置了。</div>
          <div class="text" style="margin-top:15px">我们认为，停车难能否得到有效解决，不仅关系到车主的切身利益，更是一个城市文明建设进程的风向标。城市级智慧停车体系在提高停车场使用率、降低路面停留车辆数、减少车辆有害气体排放量、实现有限资源的合理利用等方面有突出表现。</div>
        </div>
      </div>
    </div>
    <!-- 解决方案能力全景 -->
    <div id="faqj_div" class="conBox nlfa">
      <h1 class="h1-title">解决方案能力全景</h1>
      <img class="img" src="@/assets/solution/cyyq/nlfa_tcgl.png">
      <img class="img1" src="@/assets/solution/cyyq/jjfabg.png">
    </div>
    <!-- 平台典型功能介绍 -->
    <carouselBanner id="ptgn_div" ref="carouselBanner" :from="'tcgl'" />
    <!-- 适用场景 -->
    <div id="sycj_div" class="sycj-div">
      <h1 class="h1-title">适用场景</h1>
      <sycj ref="sycj" class="conBox" :from="'tcgl'" />
    </div>
    <!-- 数字嘉园 美好生活 -->
    <footers ref="footers" />
  </div>
</template>

<script>
import solutionBanner from './components/solutionBanner.vue';
import sycj from './components/sycj.vue';
import footers from './components/footer.vue';
import carouselBanner from './components/carouselBanner.vue';
import navBar from './components/navBar.vue';
export default {
  name: 'Solution',
  components: { solutionBanner, sycj, footers, carouselBanner, navBar },
  data() {
    return {
    };
  },
  watch: {
  },
  mounted() {
  },
  methods: {
  }
};
</script>

<style lang="scss" scoped>

.flex-start {
  display: flex;
  justify-content: start;
}
.flex-between {
  display: flex;
  justify-content: space-between;
}
.flex-align {
  align-items: center;
}
.conBox {
  width: 1200px;
  margin: 0 auto;
}

.fenxi-box {
  padding-bottom: 80px;
  background: #F9FBFF;
  .fenxi {
    .imgs,.img{
      width: 594px;
      height: 410px;
    }
    .text {
      font-size: 14px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      text-align: left;
      color: #666666;
      line-height: 30px;
      padding-left: 50px;
      text-align: justify;
    }
  }
}
.nlfa {
  position: relative;
  text-align: center;
  margin-bottom: 80px;
  .img {
    width: 1200px;
    height: 746px;
    margin: auto;
  }
  .img1 {
    position: absolute;
    width: 600px;
    height: 500px;
    bottom: 4%;
    right: -22%;
  }
}
</style>

